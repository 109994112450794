<template>
    <v-dialog v-model="dialog"
              persistent
              max-width="600">
        <v-card>
            <v-toolbar dense>
                <v-toolbar-title>Category List</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeView()">
                    <v-icon>fas fa-backspace</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>

            <v-list rounded v-show="showMain">
                <v-list-item-group v-model="selectedMain"
                                    color="primary">
                    <v-list-item v-for="(item, i) in items"
                                    :key="i">
                        <template v-if="item.items.length === 0">
                            <v-list-item-icon>
                                <v-icon v-text="item.iconData"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.categoryName"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-icon>
                                <v-icon v-text="item.iconData"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.categoryName"></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-icon>fas fa-arrow-circle-right</v-icon>
                            </v-list-item-icon>
                        </template>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <v-list rounded v-if="showSub">
                <v-list-item-group v-model="selectedSecond"
                                    color="primary">
                    <v-list-item>
                        <template>
                            <v-list-item-icon>
                                <v-icon v-text="subItems.iconData"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="subItems.categoryName"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item v-for="(item, i) in subItems.items"
                                    :key="i">
                        
                        <template>
                            <v-list-item-icon>
                                <v-icon v-text="item.iconData"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.categoryName"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-dialog>
</template>

<script>
    import { returnCategoriesObject } from '../../javascript/globalFunctions'

    export default {
        data() {
            return {
                dialog: true,
                selectedMain: '',
                selectedSecond: '',
                selectedCategory: '',
                showMain: true,
                showSub: false,
                items: [],
                subItems: []
            }
        },
        methods: {
            closeView: function () {
                if (this.showSub) {
                    this.showMain = true
                    this.showSub = false
                } else {
                    this.$emit('categoryShow', false)
                }
            },
            categorySelected: function () {
                this.$emit('selectedCategory', this.selectedCategory)
                this.closeView()
            }
        },
        watch: {
            'selectedMain': function () {
                try {
                    let category = this.items[this.selectedMain]

                    if (category.items.length === 0) {
                        this.selectedCategory = category.categoryName
                        this.categorySelected()
                    }
                    else {
                        this.showMain = false
                        this.showSub = true

                        this.subItems = category

                    }
                } catch{
                }
            },
            'selectedSecond': function () {
                if (this.selectedSecond === 0) {
                    this.selectedCategory = this.subItems.categoryName
                }
                else {
                    let category = this.subItems.items[(this.selectedSecond - 1)]

                    this.selectedCategory = category.categoryName
                }
                this.showSub = false
                this.categorySelected()
            }
        },
        beforeMount() {
            this.items = returnCategoriesObject()
        }
    }
</script>